import Layout from '../library/components/layout';
import { PageProps } from '../library/next/getStaticProps';

export { default as getStaticProps } from '../library/next/getStaticProps';

const IndexPage = (props: PageProps) => {
  return <Layout page={props.page} siteSettings={props.siteSettings} />;
};

export default IndexPage;
